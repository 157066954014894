// Custom JS Application Code
function validate_email_js(email) { var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/; if(reg.test(email) == false) { return false; } return true; }
var ellipsisText = function (e, etc) { var wordArray = e.innerHTML.split(" "); while (e.scrollHeight > e.offsetHeight) { wordArray.pop(); e.innerHTML = wordArray.join(" ") + (etc || "..."); } };

function checkHeaderOffset() {
	var $window = $(window);
	var offset = $window.scrollTop();
	if (offset>=30) {
		$('#headerWrapper').addClass('scrolled');
	}else{
		$('#headerWrapper').removeClass('scrolled');
	}
}

function windowOnResize() {
	var myWidth, myHeight;
	if( typeof( window.innerWidth ) == 'number' ) { myWidth = window.innerWidth; myHeight = window.innerHeight; }
	else if( document.documentElement && ( document.documentElement.clientWidth || document.documentElement.clientHeight ) ) { myWidth = document.documentElement.clientWidth; myHeight = document.documentElement.clientHeight; }
	else if( document.body && ( document.body.clientWidth || document.body.clientHeight ) ) { myWidth = document.body.clientWidth; myHeight = document.body.clientHeight; }
	browserWidth = myWidth;
	browserHeight = myHeight;
}
function pageScroll() {
	checkHeaderOffset();
}

function scrollToSlide(slideTo) {
	$('body,html').animate({ scrollTop: $('.'+slideTo).offset().top-$('header').height() }, 800);
}

function sendContactForm() {
	var cName = $('#cName').val();
	var cEmail = $('#cEmail').val();
	var cPhone = $('#cPhone').val();
	var cMessage = $('#cMessage').val();
	var error = false;
	var totalErrors = '';
	var params = '';

	if (cName=='' || cEmail=='' || cPhone=='' || cMessage=='') {
		$('.form-error-popup-wrapper').fadeIn();
		error = true;
	} else {
		if (!validate_email_js(cEmail)) {
			$('.form-error-popup-wrapper').fadeIn();
			error = true;
		}
	}
	if (!error) { sendContactFormToServer(); }
}

function sendContactFormToServer() {
	if (!inAjax) {
		var cName = $('#cName').val();
		var cEmail = $('#cEmail').val();
		var cPhone = $('#cPhone').val();
		var cMessage = $('#cMessage').val();

		var params = '';
		inAjax = true;
		params += 'cName='+encodeURIComponent(cName);
		params += '&cEmail='+encodeURIComponent(cEmail);
		params += '&cPhone='+encodeURIComponent(cPhone);
		params += '&cMessage='+encodeURIComponent(cMessage);

		jQuery.ajax({
			type: "POST",url: baseUrl+'send_form.php',data: params,
			complete: function(XMLHttpRequest, textStatus) {
				var response = XMLHttpRequest.responseText;
				inAjax = false;
				if (response=='success') {
					$('.form-success-popup-wrapper').fadeIn();
					//$('.gdpr-acceptance').removeClass('accepted');
					//$('.form-submit-wrapper .form-submit').addClass('disabled');
					$('#cName').val('');
					$('#cEmail').val('');
					$('#cPhone').val('');
					$('#cMessage').val('');
				}else{
					$('.form-error-popup-wrapper').fadeIn();
				}
			}
		});
	}
}

(function($) {
	"use strict"; // Start of use strict
	$(window).bind('hashchange');
	$(window).trigger('hashchange');
	$(window).scroll( function() { /*disableScroll();*/ pageScroll(); } );
	$(window).resize(function() { windowOnResize(); });
	$(window).ready(function() {
		var myWidth, myHeight;
		if( typeof( window.innerWidth ) == 'number' ) { myWidth = window.innerWidth; myHeight = window.innerHeight; }
		else if( document.documentElement && ( document.documentElement.clientWidth || document.documentElement.clientHeight ) ) { myWidth = document.documentElement.clientWidth; myHeight = document.documentElement.clientHeight; }
		else if( document.body && ( document.body.clientWidth || document.body.clientHeight ) ) { myWidth = document.body.clientWidth; myHeight = document.body.clientHeight; }
		browserWidth = myWidth;
		browserHeight = myHeight;

		//if ($(window).width()>=1024) { new WOW().init(); }
		AOS.init({once: true, disable: function() { return $(window).width()<1024; }});

		//$('.go-top a').click(function () { $('body,html').animate({ scrollTop: 0 }, 800); $(this).blur(); return false; });

		$('.nav-toggle').bind('click',function(ev) {
			if ($('.nav-wrapper').hasClass('opened')) {
				$('.nav-wrapper').fadeOut(0,function() {
					$(this).removeClass('opened');
					$('#headerWrapper').removeClass('opened');
				});
				$(this).removeClass('is-active');
			}else{
				$('#headerWrapper').addClass('opened');
				$('.nav-wrapper').fadeIn(0,function() {
					$(this).addClass('opened');
				});
				$(this).addClass('is-active');
			}

			$(this).blur();
			ev.preventDefault();
		});

		$('.close-popup').bind('click',function(ev) {
			$(this).closest('.popup-wrapper').fadeOut();
			$('body').removeClass('in-popup');
			$(this).blur(); ev.preventDefault();
		});

		$('.gdpr-acceptance').bind('click',function(ev) {
			$(this).toggleClass('accepted');
			$('.form-submit-wrapper .form-submit').toggleClass('disabled');
			$(this).blur(); ev.preventDefault();
		});

		$('.form-bottom a.form-submit').bind('click',function(ev) {
			sendContactForm();
			$(this).blur(); ev.preventDefault();
		});

		$('.toggle-sound').bind('click',function(ev) {
			if ($(this).hasClass('sound-off')) {
				$(this).removeClass('sound-off');
				$(this).addClass('sound-on');
				$('#video1').prop('muted',false);
			}else if ($(this).hasClass('sound-on')) {
				$(this).removeClass('sound-on');
				$(this).addClass('sound-off');
				$('#video1').prop('muted',true);
			}
			$(this).blur(); ev.preventDefault();
		});
	});
	$(window).on('load',function() {

	});

})(jQuery); // End of use strict


var browserWidth = 0;
var browserHeight = 0;
var inAjax = false;
